import Sentry from "javascript/Sentry/Wrapper";
import loadjs from "loadjs";
import YoutubePlayer from "javascript/YoutubePlayer";

export default function setupVideoPlayerPorts(ports) {
  ports.initializeYoutube.subscribe(() => {
    YoutubePlayer.load(ports, loadjs);
  });

  ports.playVideo.subscribe(([videoKey, storedCurrentTime]) => {
    playVideo(ports, videoKey, storedCurrentTime);
  });

  ports.pauseVideo.subscribe(() => {
    const video = document.querySelector("video");
    if (video) video.pause();
  });
}

function playVideo(ports, videoKey, storedCurrentTime, attempt = 0) {
  // We want to select the right video element to play. In most cases, there is
  // only one video element (e.g. on the activity practice view)
  // However, on the subject choice view, we may need to differentiate
  // between possibly two videos (i.e. math feedback modal OR reading feedback modal)
  const videoQuerySelector = `#${videoKey} > video`;
  const video = document.querySelector(videoQuerySelector);

  Sentry.addBreadcrumb({
    message: `Looking for video on page: {videoKey: ${videoKey}, attempt: ${attempt}, foundVideo: ${!!video}}`,
    category: "VideoPlayer",
    level: "debug"
  });

  if (video === null) {
    console.error("Null video error", videoKey, storedCurrentTime);
    if (attempt < 2) {
      setTimeout(function() {
        playVideo(ports, videoKey, storedCurrentTime, attempt + 1);
      }, 200 + attempt * 1000);
      return;
    } else {
      const extra = {
        videoKey,
        hasNativeVideoPlayerContainer: Boolean(document.querySelector(`#${videoKey}`)),
        hasYoutubeVideoPlayer: Boolean(document.querySelector(".video-player")),
        teacherFeedbackRoute: teacherFeedbackRoute(videoKey),
        activityOrIntroRoute: activityOrIntroRoute(videoKey)
      };
      console.log(extra);

      // One issue we keep encountering in the logs is that students start a video and then move to
      // another page while the video continues to initialize.  The videoKey is structured differently
      // depending on the route we are on but there are only three cases.  So we can compare key and route:

      // These are the routes for video activities, activity intros, and teacher feedback modal respectively.
      // videoKey = "activity-practice-STUDENT_ACTIVITY_ID" for "activities/STUDENT_ACTIVITY_ID/practice"
      // videoKey = "video-player-STUDENT_ACTIVITY_ID" for "activities/STUDENT_ACTIVITY_ID/practice"
      // videoKey = "video-player-RANDOM_ID" for "/student"
      if (teacherFeedbackRoute(videoKey) || activityOrIntroRoute(videoKey)) {
        Sentry.captureMessage("Tried to play video, but could not find video on page.", {
          extra
        });
      }
      ports.videoPlaybackErrored.send(extra);
    }
    return;
  }
  if (attempt > 0) {
    Sentry.captureMessage("Playing video worked after a retry");
  }
  let startedPlaying = false;

  const timeUpdateHandler = event => {
    ports.videoTimeUpdate.send([
      videoKey,
      {
        currentTime: event.target.currentTime,
        duration: event.target.duration
      }
    ]);
  };

  const playAndTrack = () => {
    if (startedPlaying) {
      return;
    }

    startedPlaying = true;
    video.currentTime = storedCurrentTime;
    // Workaround for iOS Safari: https://stackoverflow.com/a/50951559
    video.addEventListener("loadeddata", () => (video.currentTime = storedCurrentTime));

    video.addEventListener("timeupdate", timeUpdateHandler);
    // Older browsers don't return a promise for play
    const playPromise = video.play();
    if (typeof playPromise !== "undefined") {
      playPromise.catch(err => {
        console.error(err);
        const extra = {
          videoKey,
          hasNativeVideoPlayerContainer: Boolean(document.querySelector(`#${videoKey}`)),
          hasYoutubeVideoPlayer: Boolean(document.querySelector(".video-player"))
        };
        Sentry.captureException(err, {
          ...extra,
          tags: {
            component: "VideoPlayer",
            caught: "true",
            errorName: err.name
          }
        });
        ports.videoPlaybackErrored.send(extra);
      });
    }
  };

  try {
    // If the metadata has already loaded (e.g., they're re-playing it for example)
    if (!isNaN(video.duration)) {
      playAndTrack();

      // the metadata hasn't finished loading, so lets wait to to do stuff requiring those values.
    } else {
      video.addEventListener("metadataloaded", () => {
        playAndTrack();
      });

      // sometimes the video won't fire the metadataloaded event but will get ready
      let attemptedCount = 0;
      const waitForReadiness = () => {
        if (!isNaN(video.duration)) {
          playAndTrack();
        } else {
          attemptedCount += 1;
          if (attemptedCount < 10) {
            setTimeout(waitForReadiness, 500);
          }
        }
      };
      waitForReadiness();
    }
  } catch (e) {
    console.error(e);
    Sentry.captureException(e, {
      tags: { caught: "true" }
    });
  }
}

const teacherFeedbackRoute = videoKey => {
  return videoKey.includes("video-player") && !window.location.href.includes("activities");
};

const activityOrIntroRoute = videoKey => {
  return (
    window.location.href.includes(videoKey.split("-")[2]) &&
    window.location.href.includes("/practice")
  );
};
