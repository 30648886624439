import Sentry from "javascript/Sentry/Wrapper";

export default class YoutubePlayer {
  static load(ports, loadjs) {
    // By default, don't use Youtube in tests
    if (window._CYPRESS && !Boolean(localStorage.getItem("V5-TEST-YOUTUBE-ENABLED"))) {
      return Promise.reject();
    }

    window.onYouTubeIframeAPIReady = () => {
      Sentry.addBreadcrumb({
        message: "Youtube is available",
        category: "YoutubePlayer",
        level: "debug"
      });

      ports.youtubeAvailable.send();

      ports.connectToEmbeddedYoutubePlayer.subscribe(
        ({ studentActivityId, activityKey, startTime }) => {
          window.requestAnimationFrame(() => {
            let videoPlaybackTimeTracker;
            let trackTime;
            // As of 11/2019, the only apparent way to detect Youtube videos that are restricted
            // is to detect videos that never get a quality other than "unknown". (Youtube
            // doesn't explicitly reveal that info.) If this changes, we would probably have to
            // fall back to rendering our oown play button (at which point plays would not count
            // for creators) and detect if playback hasn't started after the button is pressed.
            let hasSeenQuality = false;
            let connectTime = Date.now();
            // 2019/11/14 AHK: 5 seconds may not be the best timeout -- we will measure below to
            // see what value is best.
            const markYoutubeVideoAsRestrictedByData = setTimeout(
              () => ports.markYoutubeVideoAsRestrictedByData.send(studentActivityId),
              5000
            );
            const player = new YT.Player("youtube-player", {
              events: {
                onReady: () => {
                  // jump to wherever they left off last time
                  player.seekTo(startTime, true);
                  trackTime = () => {
                    // If we have moved on from the player page, then there are cases where it
                    // isn't cleaned up and still playing in a detached iframe.  One case is when
                    // you have mastered a quiz and are clicking next through the video
                    // activities. The video player never finishes, and the iframe is detached.
                    // In these cases, we need to force a cleanup.
                    //This also occurs when there's a problem with the youtube player and the media server version replaces it
                    if (player.getIframe && !document.contains(player.getIframe())) {
                      console.error(
                        "youtube player detached",
                        player.getIframe,
                        player.getIframe()
                      );
                      player.stopVideo();
                      player.destroy();
                      clearInterval(videoPlaybackTimeTracker);
                    } else {
                      if (player.getPlayerState() === YT.PlayerState.PLAYING) {
                        // convert to milliseconds
                        ports.embeddedYoutubeVideoTimeUpdate.send([
                          activityKey,
                          {
                            currentTime: player.getCurrentTime(),
                            duration: player.getDuration()
                          }
                        ]);
                      }
                    }
                  };

                  videoPlaybackTimeTracker = setInterval(trackTime, 1000);

                  ports.playEmbeddedYoutubePlayer.subscribe(() => {
                    try {
                      console.log("try and playVideo...");
                      player.playVideo();
                    } catch (err) {
                      console.error("play embedded youtube player err:", err);
                      Sentry.captureException(err, {
                        tags: {
                          component: "YoutubeVideoPlayer",
                          caught: "true",
                          errorName: err.name
                        }
                      });
                    }
                  });

                  ports.pauseEmbeddedYoutubePlayer.subscribe(() => {
                    try {
                      player.pauseVideo();
                    } catch (err) {
                      console.error("pause embedded youtube player err:", err);
                      Sentry.captureException(err, {
                        tags: {
                          component: "YoutubeVideoPlayer",
                          caught: "true",
                          errorName: err.name
                        }
                      });
                    }
                  });

                  ports.restartEmbeddedYoutubeVideo.subscribe(() => {
                    videoPlaybackTimeTracker = setInterval(trackTime, 1000);
                    player.seekTo(0.1, true);
                    player.playVideo();
                  });
                },
                onStateChange: ({ target, data }) => {
                  // 2019/11/14 AHK: Right now, the timeout for the playbackQuality measurement
                  // is a guess -- not too long as for students to ask teachers for help, but
                  // possibly too short. We want to measure how long it takes so that we can use
                  // the right interval.
                  // (We won't get values above the timeout, but can use this number to reduce
                  // the number of MarkVideoAsRestrictedByData messages.)
                  if (!hasSeenQuality && target.getPlaybackQuality() !== "unknown") {
                    hasSeenQuality = true;
                    ports.trackTimeToYoutubeQualityMetric.send(Date.now() - connectTime);
                    clearTimeout(markYoutubeVideoAsRestrictedByData);
                  }
                  // TODO: unsubscribe on page change
                  switch (data) {
                    case window.YT.PlayerState.ENDED:
                      ports.embeddedYoutubePlayerPlaybackEnded.send(studentActivityId);
                      clearInterval(videoPlaybackTimeTracker);
                      break;
                    case window.YT.PlayerState.PAUSED:
                      ports.embeddedYoutubePlayerPlaybackPaused.send();
                      break;
                    case window.YT.PlayerState.PLAYING:
                      // we've started playing from some other state, so immediately track the time
                      trackTime();
                      break;

                    default:
                      return;
                  }
                }
              }
            });
          });
        }
      );
    };

    // For Youtube Player docs: https://developers.google.com/youtube/iframe_api_reference
    return loadjs("https://www.youtube.com/iframe_api", { returnPromise: true }).catch(() => {
      Sentry.addBreadcrumb({
        message: "Youtube is NOT available",
        category: "YoutubePlayer",
        level: "debug"
      });

      ports.youtubeUnavailable.send();
    });
  }
}
