import { ElmPorts } from "../Types/ElmPorts";
import { HeartbeatData, HeartbeatService } from "./heartbeatService";

type Ports = ElmPorts<{
  subscribe: {
    setHeartbeatData: HeartbeatData;
  };
  send: {};
}>;

type PlatformData = {
  serverBaseUrl: string;
};

type WindowWithHeartbeat = typeof window & { heartbeat: HeartbeatService };

export function setupHeartbeatPorts(ports: Ports, platformData: PlatformData) {
  ports.setHeartbeatData.subscribe(data => {
    getHeartbeatService(platformData.serverBaseUrl, data).setData(data);
  });
}

function getHeartbeatService(serverBaseUrl: string, data: HeartbeatData) {
  const typedWindow = window as WindowWithHeartbeat;
  if (typedWindow.heartbeat) return typedWindow.heartbeat;

  typedWindow.heartbeat = new HeartbeatService(serverBaseUrl, data);
  return typedWindow.heartbeat;
}
