const ONE_MINUTE = 60000;
const ACTIVITY_CHECK_INTERVAL_DEFAULT = ONE_MINUTE / 2;
const INACTIVITY_INTERVAL_DEFAULT = ONE_MINUTE * 5;
const ACTIVITY_EVENTS_DEFAULT = ["mousemove", "keydown", "touchstart", "click"];

/*
 Check for inactivity and call a callback if the user is inactive.
 We listen for activity (mouse or keyboard interaction), and once we have it,
 we reset two timers:
  1. The activity check timer, which will listen again for activity after ACTIVITY_CHECK_INTERVAL time
  2. The inactivity timer, which will call the callback after INACTIVITY_INTERVAL time
 We could just have the inactivity timer, and constantly be listening for mouse or keyboard interaction,
 but that could be a performance issue because we would be acting on every single mouse or keyboard event.
 Once we've decided we're inactive, we listen for activity again and call a different callback when we've restarted activity.
 */
export class InactivityChecker {
  onInactiveCallback: () => void;
  onActiveAgainCallback: () => void;
  inactivityInterval: number;
  activityCheckInterval: number;
  activityEvents: string[];
  counter = 0;
  currentlyInactive = false;

  activityCheckTimeout?: NodeJS.Timer;
  inactivityTimeout?: NodeJS.Timer;
  abortController?: AbortController;

  constructor(
    onInactiveCallback: () => void,
    onActiveAgainCallback: () => void,
    options: {
      inactivityInterval?: number;
      activityCheckInterval?: number;
      activityEvents?: string[];
    } = {}
  ) {
    this.onInactiveCallback = onInactiveCallback;
    this.onActiveAgainCallback = onActiveAgainCallback;
    this.inactivityInterval = options.inactivityInterval || INACTIVITY_INTERVAL_DEFAULT;
    this.activityCheckInterval = options.activityCheckInterval || ACTIVITY_CHECK_INTERVAL_DEFAULT;
    this.activityEvents = options.activityEvents || ACTIVITY_EVENTS_DEFAULT;
  }

  start() {
    this.reset();
  }

  stop() {
    this.cleanUp();
  }

  private listenForActivity() {
    const count = this.counter;
    this.counter++;
    const abortController = new AbortController();
    this.abortController = abortController;
    const listenerOptions = { signal: abortController.signal, passive: true, capture: false };

    this.activityEvents.forEach(event => {
      document.addEventListener(event, this.onActivity.bind(this), listenerOptions);
    });
  }

  private onActivity() {
    if (this.currentlyInactive) {
      this.currentlyInactive = false;
      this.onActiveAgainCallback();
    }
    this.reset();
  }

  private onInactivity() {
    this.cleanUp();
    this.listenForActivity();
    this.onInactiveCallback();
    this.currentlyInactive = true;
  }

  private reset() {
    this.cleanUp();
    this.setTimeouts();
  }

  private cleanUp() {
    if (this.inactivityTimeout) clearInterval(this.inactivityTimeout);
    if (this.activityCheckTimeout) clearInterval(this.activityCheckTimeout);
    if (this.abortController) this.abortController.abort();
  }

  private setTimeouts() {
    this.activityCheckTimeout = setTimeout(
      this.listenForActivity.bind(this),
      this.activityCheckInterval
    );
    this.inactivityTimeout = setTimeout(this.onInactivity.bind(this), this.inactivityInterval);
  }
}
