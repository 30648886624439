import { v4 as uuidv4 } from "uuid";
import authedFetch from "../util/authedFetch";
import { InactivityChecker } from "./inactivityChecker";

const CURRENT_EVENT_VERSION = "1.0.0";
const HEARTBEAT_INTERVAL = 5000;

const ALLOWED_KEYS = [
  "event_type",
  "event_id",
  "client_event_version",
  "student_id",
  "organization_id",
  "student_quest_id",
  "student_activity_id",
  "quest_id",
  "activity_id",
  "subject_area_name",
  "subject_area_id"
];

const HELLO_HEARTBEAT = "hello";
const GOODBYE_HEARTBEAT = "goodbye";
const STANDARD_HEARTBEAT = "heartbeat";

export type HeartbeatData = Record<string, number | string>;

export class HeartbeatService {
  serverBaseUrl: string;
  timeBetweenBeats: number;
  data: HeartbeatData = {};
  counter?: number;
  initializedTimestamp?: number;
  mostRecentTimestamp?: number;
  heartbeatInterval?: NodeJS.Timer;
  inactivityChecker?: InactivityChecker;

  constructor(
    serverBaseUrl: string,
    initialData: HeartbeatData = {},
    timeBetweenBeats = HEARTBEAT_INTERVAL
  ) {
    this.serverBaseUrl = serverBaseUrl;
    this.timeBetweenBeats = timeBetweenBeats;
    this.setData(initialData);
    this.hello();
    this.setUpListeners();
  }

  publishBeat() {
    if (this.counter === undefined || this.initializedTimestamp === undefined) {
      this.hello();
    } else {
      this.beat();
      this.publish();
    }
  }

  hello() {
    if (this.counter === 0) return; // just said hello
    this.resetCounterAndTimestamps();
    this.publish(HELLO_HEARTBEAT);

    if (this.heartbeatInterval) clearInterval(this.heartbeatInterval);
    this.heartbeatInterval = setInterval(this.publishBeat.bind(this), this.timeBetweenBeats);

    if (!this.inactivityChecker)
      this.inactivityChecker = new InactivityChecker(
        this.goodbye.bind(this),
        this.hello.bind(this)
      );
    this.inactivityChecker.start();
  }

  goodbye() {
    if (this.heartbeatInterval) clearInterval(this.heartbeatInterval);
    if (this.counter === undefined) return; // already said goodbye
    this.beat();
    this.publish(GOODBYE_HEARTBEAT);
    this.counter = undefined;
    this.initializedTimestamp = undefined;
    this.mostRecentTimestamp = undefined;
  }

  setData(data: HeartbeatData) {
    this.data = this.cleanData(data);
  }

  private setUpListeners() {
    document.addEventListener("visibilitychange", this.onVisibilityChange.bind(this), {
      passive: true
    });
    document.addEventListener("beforeunload", () => this.goodbye.bind(this));
  }

  private beat() {
    if (this.counter !== undefined) this.counter++;
    this.mostRecentTimestamp = Date.now();
  }

  private publish(event_type = STANDARD_HEARTBEAT) {
    const baseData = {
      counter: this.counter,
      time_since_initialized: this.timeSinceInitialized,
      event_id: uuidv4(),
      client_event_version: CURRENT_EVENT_VERSION,
      event_type
    };
    const payload = Object.assign(baseData, this.data);
    authedFetch(`${this.serverBaseUrl}/api/v6/student/heartbeats`, {
      method: "POST",
      body: JSON.stringify({ heartbeat: payload })
    });
  }

  private onVisibilityChange() {
    if (document.hidden) {
      this.goodbye();
    } else {
      this.hello();
    }
  }

  private cleanData(data: HeartbeatData): HeartbeatData {
    const cleanedData: HeartbeatData = {};
    for (const [key, value] of Object.entries(data)) {
      if (!ALLOWED_KEYS.includes(key)) continue;
      if (value === undefined || value === null) continue;
      const asInt = parseInt(data[key] as string);
      cleanedData[key] = asInt ? asInt : data[key];
    }
    return cleanedData;
  }

  private get timeSinceInitialized(): number {
    if (this.initializedTimestamp === undefined || this.mostRecentTimestamp === undefined) return 0;

    return Math.floor((this.mostRecentTimestamp - this.initializedTimestamp) / 1000);
  }

  private resetCounterAndTimestamps() {
    this.counter = 0;
    this.initializedTimestamp = Date.now();
    this.mostRecentTimestamp = this.initializedTimestamp;
  }
}
